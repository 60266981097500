import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { CategoryModel, CategoryModelSave } from '../models/category-model';
import { AbstractRegistrationService } from './abstract-registration-service';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class CategoryService extends AbstractRegistrationService<CategoryModel, CategoryModelSave> {
  findPageCount(text?: string, limit: number = 10): Promise<number> {
    const params: any = {
      limit
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-tribe/v1/category/page/count`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findPage(text?: string, page: number = 0, limit: number = 10): Promise<CategoryModel[]> {
    const params: any = {
      limit,
      offset: page
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-tribe/v1/category/page`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findAll(): Promise<CategoryModel[]> {
    return Axios.get(`${BASE_URL}/api-tribe/v1/category`)
      .then(resp => {
        return resp.data
      })
  }

  findById(id: number): Promise<CategoryModel> {
    return Axios.get(`${BASE_URL}/api-tribe/v1/category/page?category-id=${id}`)
      .then(resp => {
        return resp.data[0]
      })
  }

  save(value: CategoryModelSave): Promise<CategoryModel> {
    const formData = new FormData()
    formData.append('name', value.name)
    formData.append('description', value.description)
    if (value.id) {
      return Axios.put(`${BASE_URL}/api-tribe/v1/category/${value.id}`, formData)
        .then(response => response.data)
    } else {
      return Axios.post(`${BASE_URL}/api-tribe/v1/category`, formData)
        .then(response => response.data)
    }
  }

  delete(id: string): Promise<any> {
    return Axios.delete(`${BASE_URL}/api-tribe/v1/category/${id}`).then()
  }
}