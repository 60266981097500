import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { AbstractRegistrationService } from './abstract-registration-service';
import { DenounceReasonModel, DenounceReasonModelSave } from '../models/denounce-reason-model';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class DenounceReasonService extends AbstractRegistrationService<DenounceReasonModel, DenounceReasonModelSave> {
  static findDenounceReasonTypes(): {type: string, description: string}[] {
    return [
      { type: 'nudity_sexual_ativity', description: 'Nudity or sexual activity' },
      { type: 'symbols_hate_speech', description: 'Symbols or hate speech' },
      { type: 'violence_dangerous_organizations', description: 'Violence or dangerous organizations' },
      { type: 'sale_regulated_illegal_products', description: 'Sale of regulated or illegal products' },
      { type: 'bullying_harassment', description: 'Bullying or harassment' },
      { type: 'infringement_intellectual_property', description: 'Infringement of intellectual property' },
      { type: 'suicide_self_mutilation_eating_disorders', description: 'Suicide, self-mutilation or eating disorders' },
      { type: 'scam_fraud', description: 'Scam or fraud' },
      { type: 'false_information', description: 'False information' },
      { type: 'others', description: "Others" },
    ]
  }
  
  findPageCount(text?: string, limit: number = 10): Promise<number> {
    const params: any = {
      limit
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/reasons/page/count`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findPage(text?: string, page: number = 0, limit: number = 10): Promise<DenounceReasonModel[]> {
    const params: any = {
      limit,
      offset: page
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/reasons/page`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findAll(): Promise<DenounceReasonModel[]> {
    return Axios.get(`${BASE_URL}/api-denounce/v1/reasons`)
      .then(resp => {
        return resp.data
      })
  }

  findById(id: number): Promise<DenounceReasonModel> {
    return Axios.get(`${BASE_URL}/api-denounce/v1/reasons/page?denounce-reason-id=${id}`)
      .then(resp => {
        return resp.data[0]
      })
  }

  save(value: DenounceReasonModelSave): Promise<DenounceReasonModel> {
    if (value.id) {
      return Axios.put(`${BASE_URL}/api-denounce/v1/reasons/${value.id}`, value)
        .then(response => response.data)
    } else {
      return Axios.post(`${BASE_URL}/api-denounce/v1/reasons`, value)
        .then(response => response.data)
    }
  }

  delete(id: string): Promise<any> {
    return Axios.delete(`${BASE_URL}/api-denounce/v1/reasons/${id}`).then()
  }
}