import { Button, createStyles, makeStyles } from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';
import { fileToSrc } from '../../shared/utils/utils';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: '100%',
      height: '150px',
      marginBottom: '10px',
      objectFit: 'cover'
    },
    input: {
      height: '0px',
      width: '0px',
      overflow: 'hidden'
    }
  })
)

interface ImageInputProps {
  image: any
  onSelectImage?: (file: File) => void
  onDeleteImage?: () => void
}

function ImageInput({ image, onSelectImage, onDeleteImage }: ImageInputProps) {
  const classes = useStyles()
  const [_image, _setImage] = useState<string>()

  let inputRef: HTMLInputElement | null

  useEffect(() => {
    if (image instanceof File) {
      fileToSrc(image)
        .then(src => {
          _setImage(src)
        })
    } else {
      _setImage(image)
    }
  }, [image])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files
    if (files && files.length > 0) {
      const file = files.item(0)
      if (file) {
        onSelectImage && onSelectImage(file)
        fileToSrc(file)
          .then(src => {
            _setImage(src)
          })
      }
    }
  }

  const openInput = () => {
    if (inputRef) {
      inputRef.click()
    }
  }
  return (
    <div>
      { _image ? (
        <>
          <img className={classes.image} src={_image} alt="preview" />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteImage ? onDeleteImage() : _setImage(undefined)}
          >
            Delete Image
          </Button>
        </>
      ) : (
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={() => openInput()}
        >
          Upload Image
        </Button>
      )}
      <input 
        ref={ref => { inputRef = ref}} 
        className={classes.input} 
        type="file"
        accept="image/*"
        onChange={onChange} />
    </div>
  );
}

export default ImageInput;