import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { TranslationAreaModel } from '../models/translation-area-model';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class TranslationAreaService {
  findAll(): Promise<TranslationAreaModel[]> {
    return Axios.get(`${BASE_URL}/api-translation-area/v1`)
    .then(resp => {
      return resp.data
    })
  }
}