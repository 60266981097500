import { createStyles, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      position: 'absolute',
      top: '0px',
      color: theme.palette.text.secondary,
      marginTop: '-15px',
      marginBottom: '0px',
      padding: '5px',
      backgroundColor: 'white',
      fontSize: '16px'
    },
    main: {
      border: '1px solid #c0c0c0',
      borderRadius: '5px'
    },
    textFieldArea: {
      padding: '18px 10px 10px'
    }
  }),
);

interface TextListInputProps {
  listClassName?: string
  values: string[]
  error?: boolean
  label: string
  inputLabel: string
  variant?: 'standard' | 'filled' | 'outlined'
  onValuesChange: (values: string[]) => void
  onBlur?: () => void
}

function TextListInput({ values, error, label, inputLabel, variant, listClassName, onValuesChange, onBlur }: TextListInputProps) {
  const classes = useStyles()
  const [value, setValue] = useState<string>()

  const valueValid = () => value && value !== ''

  const addValue = () => {
    const _values = values || []
    if (valueValid() && !_values.includes(value!)) {
      _values.push(value!)
      onValuesChange && onValuesChange(_values)
      setValue('')
    }
  }

  const deleteValue = (index: number) => {
    const _values = values || []
    onValuesChange && onValuesChange(_values.filter((v, i) => i !== index))
  }

  const onKeyUp = (key: string) => {
    if (key === "Enter") {
      addValue()
    }
  }

  return (
    <div className={classes.main}>
      <div className={classes.textFieldArea}>
        <p className={classes.title}>{label}</p>
        <div className="d-flex w-100">
          <div className="d-flex flex-grow-1">
            <TextField 
              fullWidth={true}
              label={inputLabel}
              variant={variant || 'outlined'}
              error={error}
              onBlur={() => onBlur && onBlur()}
              value={value}
              onKeyUp={(e) => onKeyUp(e.key)}
              onChange={event => setValue(event.target.value)} 
            />
          </div>
          <div className="d-flex mx-2 flex-shrink-1">
            <IconButton edge="end" aria-label="delete" onClick={() => addValue()}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <List className={listClassName}>
        { values && values.map((value, index) => (
          <ListItem key={index}>
            <ListItemText primary={value} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => deleteValue(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        { !values && (
          <ListItem>
            <ListItemText primary="No data" />
          </ListItem>
        ) }
      </List>
    </div>
  );
}

export default TextListInput;