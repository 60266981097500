import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, TextField, 
  useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { LanguageModel, LanguageModelSave } from '../../../shared/models/language-model';

interface LanguageDetailsProps {
  value?: LanguageModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: LanguageModelSave) => void
}

const createForm = (value?: LanguageModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('sigla', [Validators.required, Validators.pattern(/^[a-z]{2}_[A-Z]{2}$|^[a-z]{2}$/)], value ? value.sigla : undefined),
    new FormField('description', [Validators.required], value ? value.description : undefined),
  ])
}

function LanguageDetails({ value, opened, onCancel, onSave }: LanguageDetailsProps) {
  const [form, setForm] = useState<FormValidation>(createForm(value))

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: LanguageModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Language Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 mb-3">
            <TextField 
              fullWidth={true}
              required={true}
              id="sigla"
              label="Sigla"
              variant="outlined"
              error={!form.fieldValid('sigla')}
              onBlur={() => setForm(form.markAsDirty('sigla'))}
              value={form.getFieldValue('sigla')}
              onChange={event => setForm(form.setValue('sigla', event.target.value))}
              helperText={'Examples: pt, pt_BR, en, en_US'}
            />
            { form.fieldHasError('sigla', 'pattern') && <Alert className="mt-2" severity="error">Sigla is inválid. Correct examples: pt, pt_BR, en, en_US!</Alert> }
            { form.fieldHasError('sigla', 'required') && <Alert className="mt-2" severity="error">Sigla is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <TextField 
              type="file"
              fullWidth={true}
              multiline={true}
              required={true}
              id="description"
              label="Description"
              variant="outlined"
              rows={4}
              error={!form.fieldValid('description')}
              onBlur={() => setForm(form.markAsDirty('description'))}
              value={form.getFieldValue('description')}
              onChange={event => setForm(form.setValue('description', event.target.value))} 
            />
            { form.fieldHasError('description', 'required') && <Alert className="mt-2" severity="error">Description is required!</Alert> }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LanguageDetails;