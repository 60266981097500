import { ValidatorFunction } from "./form-validation";

export class Validators {
  static pattern(pattern: RegExp): ValidatorFunction {
    return (value: string) => {
      if (value && value !== '' && !value.match(pattern)) {
        return 'pattern'
      }
      return null
    }
  }

  static required: ValidatorFunction = (value: string) => {
    if (value && value !== '') {
      return null
    }
    return 'required'
  } 
  
  static url: ValidatorFunction = (value: string) => {
    if (value && value !== '' && !value.match(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/)) {
      return 'url'
    }
    return null
  } 
}