import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { DenounceDetailsModel } from '../models/denounce-details-model';
import { DenounceSummaryModel } from '../models/denounce-summary-model';
import { PostDenouncesModel } from '../models/post-denounces-model';

const BASE_URL = meeTribuEnv.getBaseUrl()

type DenounceStatus = 'denounced' | 'analyzing' | 'refused' | 'accepted' | 'opened'

interface DenounceFilter {
  status?: DenounceStatus
  reasonType?: string
  postId?: string
}

export class DenounceService {
  static findDenounceDetails(denounceId: string): Promise<DenounceDetailsModel> {
    return Axios.get(`${BASE_URL}/api-denounce/v1/details/${denounceId}`)
      .then((resp) => {
        return resp.data;
      });
  }

  static findPageCount(
    filter: DenounceFilter,
    limit: number = 10): Promise<number> {
    const params: any = {
      limit,
    };
    if (filter.status && ['denounced', 'analyzing', 'refused', 'accepted'].includes(filter.status)) {
      params['status'] = filter.status.toUpperCase()
    }
    if (filter.postId) {
      params['post-id'] = filter.postId
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/page/count`, {
      params,
    }).then((resp) => {
      return resp.data;
    });
  }

  static findPage(
    filter: DenounceFilter,
    page: number = 0,
    limit: number = 10
  ): Promise<DenounceSummaryModel[]> {
    const params: any = {
      limit,
      offset: page,
    };
    if (filter.status && ['denounced', 'analyzing', 'refused', 'accepted'].includes(filter.status)) {
      params['status'] = filter.status.toUpperCase()
    }
    if (filter.reasonType) {
      params['reason-type'] = filter.reasonType.toUpperCase()
    }
    if (filter.postId) {
      params['post-id'] = filter.postId
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/page`, {
      params,
    }).then((resp) => {
      return resp.data;
    });
  }

  static findPostDenouncesPageCount(
    status: DenounceStatus,
    reasonType: string | undefined = undefined,
    limit: number = 10): Promise<number> {
    const params: any = {
      limit,
    };
    if (status && ['denounced', 'analyzing', 'refused', 'accepted'].includes(status)) {
      params['status'] = status.toUpperCase()
    }
    if (reasonType) {
      params['reason-type'] = reasonType.toUpperCase()
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/post-denounces/page/count`, {
      params,
    }).then((resp) => {
      return resp.data;
    });
  }

  static findPostDenouncesPage(
    status: DenounceStatus,
    reasonType: string | undefined = undefined,
    page: number = 0,
    limit: number = 10
  ): Promise<PostDenouncesModel[]> {
    const params: any = {
      limit,
      offset: page,
    };
    if (status && ['denounced', 'analyzing', 'refused', 'accepted'].includes(status)) {
      params['status'] = status.toUpperCase()
    }
    if (reasonType) {
      params['reason-type'] = reasonType.toUpperCase()
    }
    return Axios.get(`${BASE_URL}/api-denounce/v1/post-denounces/page`, {
      params,
    }).then((resp) => {
      return resp.data;
    });
  }

  static analyseDenounce(denounceId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/analyse/${denounceId}`)
  }

  static analyseDenouncesByPostId(postId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/analyse/post/${postId}`)
  }

  static acceptDenounce(denounceId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/accept/${denounceId}`)
  }

  static acceptDenouncesByPostId(postId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/accept/post/${postId}`)
  }

  static refuseDenounce(denounceId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/refuse/${denounceId}`)
  }

  static refuseDenouncesByPostId(postId: string) {
    return Axios.put(`${BASE_URL}/api-denounce/v1/refuse/post/${postId}`)
  }
}
