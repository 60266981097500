export declare interface ValidatorFunction {
  (value: any) : string | null
}

export const validateField = (value?: any, validators?: ValidatorFunction[]) => {
  let errors: string[] = []
  if (validators) {
    errors = validators.reduce(
      (acc, validator) => {
        const result = validator(value)
        if (result) {
          return ([...acc, result])
        } else {
          return acc
        }
      }, 
      [] as string[]
    )
  }
  return errors;
}

export class FormField {
  name: string
  validators?: ValidatorFunction[]
  value?: any
  dirty: boolean = false

  constructor(name: string, validators: ValidatorFunction[], value?: any) {
    this.name = name
    this.validators = validators
    this.value = value
  }
  
  public valid () {
    return validateField(this.value, this.validators)?.length === 0
  }

  public hasError(error: string) {
    const errors = validateField(this.value, this.validators)
    return errors.includes(error)
  }

  public setDirty = (dirty: boolean) => this.dirty = dirty

  public setValidators = (validators: ValidatorFunction[]) => this.validators = validators
}

export class FormValidation {
  fields: FormField[] = []

  constructor(fields: FormField[]) {
    this.fields = fields
  }

  public valid() {
    return this.fields.reduce((acc, field) => acc && field.valid(), true)
  }

  public getFieldValue(name: string) {
    return this.fields.find(f => f.name === name)!.value
  }

  public setValue(name: string, value: any) {
    this.fields.find(f => f.name === name)!.value = value
    return this.clone()
  }

  public getValue() {
    return this.fields.reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {} as any)
  }

  public markAsDirty(name: string)  {
    this.fields.find(f => f.name === name)!.setDirty(true)
    return this.clone()
  }

  public markAllAsDirty() {
    this.fields.forEach(field => field.setDirty(true))
    return this.clone()
  }

  public clear() {
    this.fields.forEach(field => {
      field.value = undefined
      field.dirty = false
    })
    return this.clone()
  }

  public setValidators(name: string, validators: ValidatorFunction[]) {
    this.fields.find(f => f.name === name)!.setValidators(validators)
    return this.clone()
  }

  public fieldValid (name: string) {
    const field = this.fields.find(f => f.name === name)!
    return field.dirty ?  field.valid() : true
  }

  public clone(): any {
    return new FormValidation(this.fields)
  }

  public fieldHasError(name: string, error: string) {
    const field = this.fields.find(f => f.name === name)!
    return field.dirty ? field.hasError(error) : false
  }
}

