import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { VariableModel, VariableModelSave } from '../models/variable-model';
import { AbstractRegistrationNonDeleteService } from './abstract-registration-non-delete-service';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class VariableService implements AbstractRegistrationNonDeleteService<VariableModel, VariableModelSave> {
  findPageCount(text?: string, limit: number = 10): Promise<number> {
    const params: any = {
      limit
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-variables/v1/page/count`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findPage(text?: string, page: number = 0, limit: number = 10): Promise<VariableModel[]> {
    const params: any = {
      limit,
      offset: page
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-variables/v1/page`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findById(id: number): Promise<VariableModel> {
    return Axios.get(`${BASE_URL}/api-variables/v1/page?variable-id=${id}`)
      .then(resp => {
        return resp.data[0]
      })
  }

  save(value: VariableModelSave): Promise<VariableModel> {
    return Axios.put(`${BASE_URL}/api-variables/v1/${value.code}`, value)
      .then(response => response.data)
  }

}