import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CategoryIcon from '@material-ui/icons/Category';
import ReportIcon from '@material-ui/icons/Report';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuth } from '../../contexts/hooks/useAuth';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: 'unset',
      textDecoration: 'none',
      '&:hover': {
        color: 'unset',
        textDecoration: 'none'
      }
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
  }),
);

interface SidebarProps {
  opened: boolean
}

export const Sidebar = ({ opened }: SidebarProps) => {
  const classes = useStyles();
  const auth = useAuth()
  
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: opened,
        [classes.drawerClose]: !opened,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: opened,
          [classes.drawerClose]: !opened,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <h4 className="m-0">meeTribu Admin</h4>
      </div>
      <Divider />
      <List>
        <Link className={classes.link} to="/">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/variables">
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Settings'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/languages">
          <ListItem button>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={'Languages'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/translations">
          <ListItem button>
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText primary={'Translations'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/categories">
          <ListItem button>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary={'Categories'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/tribes">
          <ListItem button>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'Tribes'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/reasons">
          <ListItem button>
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary={'Denounce Reasons'} />
          </ListItem>
        </Link>
        <Link className={classes.link} to="/denounces">
          <ListItem button>
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText primary={'Denounces'} />
          </ListItem>
        </Link>
        <ListItem button onClick={auth.signOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </Drawer>
  )
};