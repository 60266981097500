import Axios from 'axios';
import { meeTribuEnv } from '../../meetribu.env';
import { LanguageModel, LanguageModelSave } from '../models/language-model';
import { AbstractRegistrationService } from './abstract-registration-service';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class LanguageService extends AbstractRegistrationService<LanguageModel, LanguageModelSave> {
  findPageCount(text?: string, limit: number = 10): Promise<number> {
    const params: any = {
      limit
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-language/v1/page/count`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findPage(text?: string, page: number = 0, limit: number = 10): Promise<LanguageModel[]> {
    const params: any = {
      limit,
      offset: page
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-language/v1/page`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findById(id: number): Promise<LanguageModel> {
    return Axios.get(`${BASE_URL}/api-language/v1/page?language-id=${id}`)
      .then(resp => {
        return resp.data[0]
      })
  }

  save(value: LanguageModelSave): Promise<LanguageModel> {
    if (value.id) {
      return Axios.put(`${BASE_URL}/api-language/v1/${value.id}`, value)
        .then(response => response.data)
    } else {
      return Axios.post(`${BASE_URL}/api-language/v1`, value)
        .then(response => response.data)
    }
  }

  delete(id: string): Promise<any> {
    return Axios.delete(`${BASE_URL}/api-language/v1/${id}`).then()
  }

  findAll(): Promise<LanguageModel[]> {
    return Axios.get(`${BASE_URL}/api-language/v1`)
    .then(resp => {
      return resp.data
    })
  }
}