import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { AuthProvider } from './contexts/Auth';
import Main from './pages/main/Main';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#eb7424'
    },
    secondary: {
      main: '#a3a3a3'
    },
    error: {
      main: '#a10000'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;