import * as React from 'react';
import Editor, { JSONEditorOptions as EditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';

import './JSONEditor.css';

interface JSONEditorProps {
  json?: any;
  text?: any;
  name: string;
  mode?: 'form' | 'tree' | 'code' | 'view';
  modes?: ('form' | 'tree' | 'code' | 'view')[];
  onChange?: () => void;
  onChangeJSON?: (json: any) => void;
  onChangeText?: (jsonString: string) => void;
};

class JSONEditor extends React.Component<JSONEditorProps>{
  private editor: Editor | null = null;
  private editorref: HTMLDivElement | null = null;

  public componentDidMount() {
    this.createEditor();
  }

  public componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  public componentWillUpdate(nextProps: any, nextState: any) {
    if (nextProps.json) {
      // this.editor.set(nextProps.json);
      if (this.editor) {
        this.editor.update(nextProps.json);
      }
    } else if (nextProps.text) {
      if (this.editor) {
        this.editor.updateText(nextProps.text);
      }
    }
  }

  public createEditor() {
    this.editor = null;
    let mode = 'form';
    let name = 'JSON editor';
    if (this.props.mode) {
      mode = this.props.mode
    }
    if (this.props.name) {
      name = this.props.name
    }
    const container = this.editorref;
    let modes = ['form', 'tree', 'code', 'view'];
    if (this.props.modes && this.props.modes.length > 0) {
      modes = this.props.modes
    }
    const editorOptions = {
      mode,
      modes,
      name,
      search: false,
      onChange: this.props.onChange,
      onChangeJSON: this.props.onChangeJSON,
      onChangeText: this.props.onChangeText
    } as EditorOptions;
    if (container) {
      this.editor = new Editor(container, editorOptions);
      if (this.props.json) {
        this.editor.set(this.props.json);
      } else if (this.props.text) {
        this.editor.setText(this.props.text);
      }
    }
  }
  public getJSON() {
    try {
      return this.editor ? this.editor.get() : undefined;
    } catch (e) {
      return null;
    }
  }

  public render() {
    return (
      <div className='JSONEditor' ref={ref => (this.editorref = ref)} />
    );
  }
}

export default JSONEditor;