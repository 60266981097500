import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChangeEvent, useEffect, useState } from 'react';
import JSONEditor from '../../../components/json-editor/JSONEditor';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { LanguageModel } from '../../../shared/models/language-model';
import { TranslationAreaModel } from '../../../shared/models/translation-area-model';
import { TranslationModel, TranslationModelSave } from '../../../shared/models/translation-model';
import { LanguageService } from '../../../shared/services/language-service';
import { TranslationAreaService } from '../../../shared/services/translation-area-service';
import { mergeDefaultTranslation } from './defaultTranslations';

interface TranslationDetailsProps {
  value?: TranslationModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: TranslationModelSave) => void
}

const createForm = (value?: TranslationModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('languageId', [Validators.required], value ? value.languageId : undefined),
    new FormField('code', [Validators.required], value ? value.code : undefined),
    new FormField('terms', [Validators.required], value ? mergeDefaultTranslation(value.code, value.terms) : undefined),
  ])
}

function TranslationDetails({ value, opened, onCancel, onSave }: TranslationDetailsProps) {
  const [form, setForm] = useState<FormValidation>(createForm(value))
  const [languages, setLanguages] = useState<LanguageModel[]>([])
  const [areas, setAreas] = useState<TranslationAreaModel[]>([])

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: TranslationModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);

  useEffect(() => {
    const loadLanguages = () => {
      new LanguageService().findAll()
        .then(newLanguages => {
          setLanguages(newLanguages)
        })
        .catch(e => {
          console.log(e)
        })
    }

    const loadAreas = () => {
      new TranslationAreaService().findAll()
        .then(newAreas => {
          setAreas(newAreas)
        })
        .catch(e => {
          console.log(e)
        })
    }

    loadLanguages()
    loadAreas()
  }, [])
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="xl"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Translation Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 mb-3">
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="languageId" style={{ backgroundColor: 'white' }}>Language *</InputLabel>
              <Select
                error={!form.fieldValid('languageId')}
                onBlur={() => setForm(form.markAsDirty('languageId'))}
                value={form.getFieldValue('languageId')}
                onChange={(e) => setForm(form.setValue('languageId', e.target.value))}
                label="Language"
              >
                { languages.length === 0 && <MenuItem>No data</MenuItem> }
                { languages.map((item) => <MenuItem key={item.id} value={item.sigla}>{item.description}</MenuItem>)}
              </Select>
            </FormControl>
            { form.fieldHasError('languageId', 'required') && <Alert className="mt-2" severity="error">Language is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="code" style={{ backgroundColor: 'white' }}>Area *</InputLabel>
              <Select
                error={!form.fieldValid('code')}
                onBlur={() => setForm(form.markAsDirty('code'))}
                value={form.getFieldValue('code')}
                onChange={(e: ChangeEvent<any>) => {
                  const code: string = e.target.value || ''
                  setForm(form.setValue('code', code))
                  setForm(form.setValue('terms', mergeDefaultTranslation(code, '{}')))
                }}
                label="Area"
              >
                { areas.length === 0 && <MenuItem>No data</MenuItem> }
                { areas.map((item) => <MenuItem key={item.id} value={item.code}>{item.description}</MenuItem>)}
              </Select>
            </FormControl>
            { form.fieldHasError('languageId', 'required') && <Alert className="mt-2" severity="error">Area is required!</Alert> }
          </div>
        </div>
        { form.getFieldValue('terms') && (
          <div className="d-flex mb-3">
            <JSONEditor 
              name={'Translation Terms'}
              text={form.getFieldValue('terms')}
              modes={['form', 'code']}
              onChangeText={(jsonString: string) => {
                setForm(form.setValue('terms', jsonString))
              }}
            />
          </div>
        ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TranslationDetails;