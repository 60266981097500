import { Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@material-ui/core'
import { Alert, Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import Table, { Column, TableAction } from '../../../components/table/Table'
import { DenounceSummaryModel } from '../../../shared/models/denounce-summary-model'
import LaunchIcon from '@material-ui/icons/Launch'
import DenounceDetails from './DenounceDetails'
import { formatDateTime } from '../../../shared/utils/formaters'
import { DenounceService } from '../../../shared/services/denounce-service'
import { PostDenouncesModel } from '../../../shared/models/post-denounces-model'
import { UserModel } from '../../../shared/models/user-model'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckIcon from '@material-ui/icons/Check'
import ProgressDialog from '../../../components/progress-dialog/ProgressDialog'
import QuestionDialog from '../../../components/question-dialog/QuestionDialog'
import { DenounceReasonService } from '../../../shared/services/denounce-reason-service'

const TITLE = 'Denounces'

const formatStatus = (value: string) => {
  switch (value) {
    case 'analyzing':
      return 'Analyzing'
    case 'refused':
      return 'Refused'
    case 'accepted':
      return 'Accepted'
    default:
      return 'Denounced';
  }
}

const POST_DENOUNCES_COLLUMNS: Column[] = [
  { fieldName: 'creationDate', displayName: 'Date', format: (value) => formatDateTime(new Date(value)) },
  { fieldName: 'tribeName', displayName: 'Tribu' },
  { fieldName: 'user', displayName: 'Post User', format: (value: UserModel) => `${value.name} (@${value.userName})` },
  { fieldName: 'denouncesCount', displayName: 'Denuncias' },
  { fieldName: 'score', displayName: 'Score' },
]

const DENOUNCES_COLLUMNS: Column[] = [
  { fieldName: 'creationDate', displayName: 'Date', format: (value) => formatDateTime(new Date(value)) },
  { fieldName: 'userDenouncer', displayName: 'Denouncer' },
  { fieldName: 'reason', displayName: 'Reason' },
  { fieldName: 'tribeName', displayName: 'Tribu' },
  { fieldName: 'status', displayName: 'Status', format: (value) => formatStatus(value) },
]

const ACCEPT_CONFIRM_MESSAGE = 'Do you want to delete this post?'
const ACCEPT_SUCCESS_MESSAGE = 'Denounce accepted with success!'
const ACCEPT_ERROR_MESSAGE = 'Error on accept denounce!'

const REFUSE_SUCCESS_MESSAGE = 'Denounce refused with success!'
const REFUSE_ERROR_MESSAGE = 'Error on refuse denounce!'

const ANALYSE_SUCCESS_MESSAGE = 'Denounce analyse started with success!'
const ANALYSE_ERROR_MESSAGE = 'Error on start analyse denounce!'

function Denounce() {
  const [loadingPostsDenounces, setLoadingPostsDenounces] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [postsDenounces, setPostsDenounces] = useState<PostDenouncesModel[]>([])
  const [denounceId, setDenounceId] = useState<string>()
  const [status, setStatus] = useState<'denounced' | 'analyzing' | 'refused' | 'accepted' | 'opened'>('opened')
  const [reasonType, setReasonType] = useState<string | undefined>()

  const [denounces, setDenounces] = useState<DenounceSummaryModel[]>([])
  const [pageDenounces, setPageDenounces] = useState<number>(1)
  const [countDenounces, setCountDenounces] = useState<number>(1)
  const [loadingDenounces, setLoadingDenounces] = useState(false)
  const [postId, setPostId] = useState<string | undefined>();

  const [loading, setLoading] = useState(false)
  const [questionOpened, setQuestionOpened] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [postConfirm, setPostConfirm] = useState<PostDenouncesModel>()

  // eslint-disable-next-line
  useEffect(() => reload(), [status, reasonType])

  // eslint-disable-next-line
  useEffect(() => load(), [page])

  // eslint-disable-next-line
  useEffect(() => loadDenounces(), [pageDenounces])

  useEffect(() => setPageDenounces(prev => {
    if (prev === 1) {
      loadDenounces()
    }
    return 1
  // eslint-disable-next-line
  }), [postId])
  
  const load = (reset = false) => {
    if (!page) return
    setDenounces([])
    setLoadingPostsDenounces(true)
    DenounceService.findPostDenouncesPageCount(status, reasonType)
      .then((count) => {
        setCount(count)
        return DenounceService.findPostDenouncesPage(status, reasonType, reset ? 0 : page - 1) 
      })
      .then(postsDenounces => {
        setLoadingPostsDenounces(false)
        setPostsDenounces(postsDenounces)
      })
      .catch(e => {
        setLoadingPostsDenounces(false)
      })
  }

  const loadDenounces = (reset = false) => {
    if (!postId || !pageDenounces) return
    setDenounces([])
    setLoadingDenounces(true)
    DenounceService.findPageCount({ status, postId, reasonType })
      .then((count) => {
        setCountDenounces(count)
        return DenounceService.findPage({ status, postId, reasonType }, reset ? 0 : pageDenounces - 1) 
      })
      .then(denounces => {
        setLoadingDenounces(false)
        setDenounces(denounces)
      })
      .catch(e => {
        setLoadingDenounces(false)
      })
  }

  const reload = () => {
    setPage(prev => {
      if (prev === 1) {
        load()
      }
      return 1
    })
  }

  const openDenounce = (denounce: DenounceSummaryModel) => {
    setDenounceId(denounce.id)
  }

  const analyseDenounces = (postDenounces: PostDenouncesModel) => {
    setLoading(true)
    DenounceService.analyseDenouncesByPostId(postDenounces.id)
      .then(() => {
        setLoading(false)
        setSuccessMessage(ANALYSE_SUCCESS_MESSAGE)
        reload()
      })
      .catch(() => {
        setLoading(false)
        setErrorMessage(ANALYSE_ERROR_MESSAGE)
      })
  }

  const acceptDenounces = (postDenounces: PostDenouncesModel) => {
    setLoading(true)
    DenounceService.acceptDenouncesByPostId(postDenounces.id)
      .then(() => {
        setLoading(false)
        setSuccessMessage(ACCEPT_SUCCESS_MESSAGE)
        reload()
      })
      .catch(() => {
        setLoading(false)
        setErrorMessage(ACCEPT_ERROR_MESSAGE)
      })
  }

  const refuseDenounces = (postDenounces: PostDenouncesModel) => {
    DenounceService.refuseDenouncesByPostId(postDenounces.id)
      .then(() => {
        setLoading(false)
        setSuccessMessage(REFUSE_SUCCESS_MESSAGE)
        reload()
      })
      .catch(() => {
        setLoading(false)
        setErrorMessage(REFUSE_ERROR_MESSAGE)
      })
  }

  const analyseAction = useState<TableAction>({
    target: 'analyse',
    displayName: 'Analyse',
    icon: SettingsIcon,
  })[0]

  const acceptAction = useState<TableAction>({
    target: 'accept',
    displayName: 'Accept',
    icon: DeleteOutlineIcon,
  })[0]

  const refuseAction = useState<TableAction>({
    target: 'refuse',
    displayName: 'Refuse',
    icon: CheckIcon,
  })[0]

  const denounceActions = useState<TableAction[]>([
    {
      target: 'details',
      displayName: 'Details',
      icon: LaunchIcon,
    }
  ])[0]

  const statusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as any)
  };

  const reasonTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReasonType(event.target.value as any)
  };

  const detailsClosed = () => {
    setDenounceId(undefined)
    load(true)
  }

  const onSelect = ([postDenounces]: PostDenouncesModel[]) => {
    if (postDenounces) {
      setPostId(postDenounces.id)
    }
  }

  const onAction = (action: TableAction, item: PostDenouncesModel) => {
    if (action.target === 'accept') {
      setPostConfirm(item)
      setQuestionOpened(true)
    }
    if (action.target === 'refuse') {
      refuseDenounces(item)
    }
    if (action.target === 'analyse') {
      analyseDenounces(item)
    }
  }

  const onDenouncesAction = (action: TableAction, item: DenounceSummaryModel) => {
    if (action.target === 'details') {
      openDenounce(item)
    }
  }

  const actions: TableAction[] = []
  if (['denounced'].includes(status)) {
    actions.push(analyseAction)
  }

  if (['denounced', 'analyzing', 'refused', 'opened'].includes(status)) {
    actions.push(acceptAction)
  }

  if (['denounced', 'analyzing', 'accepted', 'opened'].includes(status)) {
    actions.push(refuseAction)
  }

  return (
    <div className="m-2">
      <ProgressDialog loading={loading} />
      <QuestionDialog
        message={ACCEPT_CONFIRM_MESSAGE}
        opened={questionOpened}
        onClose={() => setQuestionOpened(false)}
        onYes={() => acceptDenounces(postConfirm!)}
        onNo={() => setQuestionOpened(false)}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSuccessMessage(undefined)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      { denounceId && (
        <DenounceDetails 
          denounceId={denounceId}
          onClose={detailsClosed}
          opened={!!denounceId} />
      ) }
      <Card>
        <CardHeader title={(
          <h4 className="m-0">{TITLE}</h4>
        )} />
        <CardContent>
          <section className="mb-3 row">
            <div
              style={{ padding: 15 }}
              className="col-12 col-md-6 col-lg-4">
              <FormControl className="w-100" variant="outlined">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  value={status}
                  onChange={statusChange}
                  label="Status" >
                  <MenuItem value={'opened'}>Opened (Denounced and Analyzing)</MenuItem>
                  <MenuItem value={'denounced'}>Denounced</MenuItem>
                  <MenuItem value={'analyzing'}>Analyzing</MenuItem>
                  <MenuItem value={'refused'}>Refused</MenuItem>
                  <MenuItem value={'accepted'}>Accepted</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: 15 }} className="col-12 col-md-6 col-lg-8">
              <FormControl className="w-100" variant="outlined">
                <InputLabel id="reasonType">Denounce Reason</InputLabel>
                <Select
                  labelId="reasonType"
                  id="reasonType"
                  value={reasonType}
                  onChange={reasonTypeChange}
                  label="Denounce Reason" >
                  <MenuItem value={undefined}>All</MenuItem>
                  { DenounceReasonService.findDenounceReasonTypes().map(item => <MenuItem value={item.type}>{item.description}</MenuItem>) }
                </Select>
              </FormControl>
            </div>
          </section>
          <section className="mb-3">
            <Table
              columns={POST_DENOUNCES_COLLUMNS}
              dataSouce={postsDenounces}
              loading={loadingPostsDenounces}
              actions={actions}
              selectable={'single'}
              onSelect={onSelect}
              onAction={onAction} />
            <div className="d-flex justify-content-end">
              <Pagination 
                count={count}
                page={page} color="primary"
                onChange={(e, value) => setPage(value)} />
            </div>
          </section>
          <section>
            <Table
              columns={DENOUNCES_COLLUMNS}
              dataSouce={denounces}
              loading={loadingDenounces}
              actions={denounceActions}
              onAction={onDenouncesAction} />
            <div className="d-flex justify-content-end">
              <Pagination
                count={countDenounces}
                page={pageDenounces}
                color="primary"
                onChange={(e, value) => setPageDenounces(value)} />
            </div>
          </section>
        </CardContent>
      </Card>
    </div>
  )
}

export default Denounce
