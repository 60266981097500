import { TribeModelSave } from './tribe-model';
import Axios from 'axios';
import { meeTribuEnv } from '../../../meetribu.env';
import { TribeModel } from '../tribes/tribe-model'
import { AbstractRegistrationService } from '../../../shared/services/abstract-registration-service';

const BASE_URL = meeTribuEnv.getBaseUrl()

export class TribeService extends AbstractRegistrationService<TribeModel, TribeModelSave> {
  findPageCount(text?: string, limit: number = 10): Promise<number> {
    const params: any = {
      limit
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-tribe/v1/count`, { params })
      .then(resp => {
        return resp.data
      })
  }
  findPage(text?: string, page: number = 0, limit: number = 10): Promise<TribeModel[]> {
    const params: any = {
      limit,
      offset: page
    }
    if (text && text !== '') {
      params.text = text
    }
    return Axios.get(`${BASE_URL}/api-tribe/v1`, { params })
      .then(resp => {
        return resp.data
      })
  }

  findById(tribeId: number): Promise<TribeModel> {
    return Axios.get(`${BASE_URL}/api-tribe/v1?tribe-id=${tribeId}`)
      .then(resp => {
        return resp.data[0]
      })
  }

  save(tribe: TribeModelSave): Promise<TribeModel> {
    const formData = new FormData()
    formData.append('name', tribe.name)
    formData.append('description', tribe.description)
    formData.append('category', tribe.categoryId)
    if (tribe.image instanceof File) {
      formData.append('image.aspectRatio', 1 + '')
      formData.append('image.file', tribe.image)
    }
    if (tribe.background instanceof File) {
      formData.append('background.aspectRatio', 1 + '')
      formData.append('background.file', tribe.background)
    }
    if (tribe.site) {
      formData.append('site', tribe.site)
    }
    if (tribe.id) {
      return Axios.put(`${BASE_URL}/api-tribe/v1/${tribe.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data)
    } else {
      return Axios.post(`${BASE_URL}/api-tribe/v1`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data)
    }
  }

  delete(id: string): Promise<any> {
    return Axios.delete(`${BASE_URL}/api-tribe/v1/${id}`).then()
  }
}