import { languages } from "./constants"

export const formatLanguage = (value: string): string => {
  return languages.find(language => language.code === value)?.name || ''
}

export const formatDateTime = (value: Date): string => {
  const formatter = new Intl.DateTimeFormat('en', { dateStyle: 'medium', timeStyle: 'medium' })
  try {
    return formatter.format(value)
  } catch (error) {
    return value.toString()
  }
}