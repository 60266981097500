import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, TextField, 
  useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { CategoryModel, CategoryModelSave } from '../../../shared/models/category-model';

interface CategoryDetailsProps {
  value?: CategoryModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: CategoryModelSave) => void
}

const createForm = (value?: CategoryModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('name', [Validators.required], value ? value.name : undefined),
    new FormField('description', [Validators.required], value ? value.description : undefined),
  ])
}

function CategoryDetails({ value, opened, onCancel, onSave }: CategoryDetailsProps) {
  const [form, setForm] = useState<FormValidation>(createForm(value))

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: CategoryModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Category Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 mb-3">
            <TextField 
              fullWidth={true}
              required={true}
              id="name"
              label="Name"
              variant="outlined"
              error={!form.fieldValid('name')}
              onBlur={() => setForm(form.markAsDirty('name'))}
              value={form.getFieldValue('name')}
              onChange={event => setForm(form.setValue('name', event.target.value))} 
            />
            { form.fieldHasError('name', 'required') && <Alert className="mt-2" severity="error">Name is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <TextField 
              type="file"
              fullWidth={true}
              multiline={true}
              required={true}
              id="description"
              label="Description"
              variant="outlined"
              rows={4}
              error={!form.fieldValid('description')}
              onBlur={() => setForm(form.markAsDirty('description'))}
              value={form.getFieldValue('description')}
              onChange={event => setForm(form.setValue('description', event.target.value))} 
            />
            { form.fieldHasError('description', 'required') && <Alert className="mt-2" severity="error">Description is required!</Alert> }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategoryDetails;