import { Button, ButtonBaseProps, withStyles } from '@material-ui/core';
import React, { ReactNode } from 'react'

interface CustomButtonProps extends ButtonBaseProps {
  backgroundColor?: string
  color?: string
  children?: ReactNode
  variant?: string
}

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>((props, ref) => {

  let InternalCustomButton: any = Button
  if (props.backgroundColor) {
    InternalCustomButton = withStyles((theme) => ({
      root: {
        backgroundColor: props.backgroundColor,
      },
    }))(InternalCustomButton);
  }

  if (props.color) {
    InternalCustomButton = withStyles((theme) => ({
      root: {
        color: props.color,
      },
    }))(InternalCustomButton);
  }

  return (
    <InternalCustomButton {...props} ref={ref}>{props.children}</InternalCustomButton>
  )
})

export default CustomButton
