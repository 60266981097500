export const mergeDefaultTranslation = (code: string, terms: string): string => {
  const parsedJSON = JSON.parse(terms)
  const defaultTranslation = defaultTranslations[code]
  return JSON.stringify(replaceStringValues(defaultTranslation, parsedJSON))
}

const replaceStringValues = (source: any, replaceable: any) => {
  const result: any = { ...source }
  for (const key in result) {
    const element = result[key];
    if (typeof element === 'string') {
      result[key] = replaceable[key] || result[key]
    } else {
      result[key] = replaceStringValues(result[key], replaceable[key] || {})
    }
  }
  return result
}

export const defaultTranslations: any = {
  Main2: {
    global: {
      or: 'OR',
      and: 'and',
      otherPeopleCount: '#0 other people',
      save: 'Save',
      done: 'Done',
      cancel: 'Cancel',
      new: 'New',
      countryPickerPlaceholder: 'Type your country...',
      follow: 'Follow',
      unfollow: 'Following',
      signOut: 'Sign Out',
      publish: 'Publish',
      yes: 'Yes',
      no: 'No',
      edit: 'Edit',
      exclude: 'Delete',
      likes: 'likes',
      previous: 'Previous',
      next: 'Next',
      finish: 'Finish',
      skip: 'Skip',
      score: 'Score',
      seeMore: 'see more',
      seeLess: 'see less',
      toDenounce: 'Denounce',
      search: 'Search...',
      continueWithApple: 'Continue with Apple',
      close: 'Close'
    },
    password: {
      minCharacters: 'At least 8 characters',
      caseSensitive: 'Use both uppercase and lowercase letters',
      specialCaracters: 'Use special characters. ex: ! @ # ? $',
      numbers: 'Use letters and numbers',
    },
    facebookButton: {
      facebookLogin: 'Sign In with Facebook',
      facebookLoginAborted: 'Facebook sign in aborted',
    },
    googleButton: {
      googleLogin: 'Sign In with Google',
    },
    signInScreen: {
      usernameOrEmailInvalid: 'The user entered is not correct. Please re-enter.',
      passwordInvalid: 'The password entered is not correct. Please re-enter.',
      usernameOrEmail: 'Username or Email',
      password: 'Password',
      signIn: 'Sign In',
      dontHaveAccount: "Don't have an account?",
      signUp: 'Sign Up',
      lostPassword: 'Forgot your password?',
      recover: 'Recover Here',
      signInProviderError: 'Could not access. Try again or choose another access method.'
    },
    signUpScreen: {
      title: 'Sign Up',
      signUpButton: 'Sign Up',
      back: 'Back',
      user: {
        name: 'Name',
        email: 'E-mail',
        username: 'Username',
        phone: 'Phone',
        password: 'Password'
      },
      validators: {
        nameRequired: 'Name is required',
        emailRequired: 'E-mail is required',
        emailInvalid: 'E-mail entered is invalid',
        usernameRequired: 'Username is required',
        usernameInUse: 'Username already in use',
        usernameInvalid: 'Username can only contain letters and numbers',
        phoneRequired: 'Phone is required',
        passwordRequired: 'Password is required',
        passwordWeak: 'Password very weak'
      },
      signInProviderError: 'Could not access. Try again or choose another access method.'
    },
    verificationPhoneScreen: {
      title: 'To proceed with the verification, we need your cell phone number',
      askPhone: 'Please enter your mobile number',
      phone: 'Phone',
      sendButton: 'Send code by SMS',
      cancel: 'Cancel',
      sendCodeUpError: "Verification code couldn't be sent",
    },
    verificationScreen: {
      title: 'Verification',
      askCode: 'Please enter your verification code',
      notice: 'Your code was sended by SMS',
      resendTimer: "Didn't receive the SMS with the verification code? You will be able to resend in ",
      resendTimerHighlight: '#0 seconds',
      smsDontArriveAsk: "Didn't receive the SMS with the verification code?",
      resendCode: 'Resend SMS',
      verifyCodeError: 'Invalid verification SMS code',
      cancel: 'Cancel',
      verify: 'Verify'
    },
    recoverPhoneScreen: {
      title: 'To proceed with the recover, we need your cell phone number',
      askPhone: 'Please enter your mobile number',
      phone: 'Phone',
      sendButton: 'Send code by SMS',
      cancel: 'Cancel',
      accountNotFound: 'User account not found',
    },
    recoverScreen: {
      title: 'Recovery',
      askCode: 'Please enter your recovery code',
      notice: 'Your code was sended by SMS',
      resendTimer: "Didn't receive the SMS with the recovery code? You will be able to resend in ",
      resendTimerHighlight: '#0 seconds',
      smsDontArriveAsk: "Didn't receive the SMS with the recovery code?",
      resendCode: 'Resend SMS',
      invalidRecoverCode: 'Invalid recovery SMS code',
      cancel: 'Cancel',
      verify: 'Verify'
    },
    changePasswordScreen: {
      passwordWeak: 'Password Weak!',
      title: 'New Password',
      subtitle: 'Enter your new password below and confirm to access meeTribu',
      password: 'Password',
      confirmPassword: 'Confirm new password',
      showError: 'The passwords entered are different',
      changePassword: 'Change Password',
      back: 'Back'
    },
    errors: {
      USERNAME_ALREADY_IN_USE: 'Username already in use',
      EMAIL_ALREADY_IN_USE: 'E-mail already in use',
      PHONE_ALREADY_IN_USE: 'Phone already in use',
      INAPPROPRIATE_CONTENT: "Posting with Racist, Pornographic, Violence and Pedophile content is strictly prohibited. Posts with these characteristics will be excluded and the user will be banned",
      DIFFERENT_PHONE: 'The phone number provided differs from the registered phone number',
      RECAPTCHA_REQUIRED: 'reCAPTCHA is required',
      USER_NOT_FOUND: 'User not found',
      INVALID_EMAIL: 'Invalid email'
    },
    profileScreen: {
      title: 'Your profile',
      followers: 'Followers',
      following: 'Following',
      editButton: 'Edit Profile',
      feedTitle: 'Feed',
      emptyFeedReport: "You don't have any posts yet",
      emptyFeedMessage: 'From the third post you start your scores',
      editPhoto: 'Edit photo'
    },
    userProfileScreen: {
      title: 'Profile of #0',
      followers: 'Followers',
      following: 'Following',
      editButton: 'Edit Profile',
      follow: 'Follow',
      unfollow: 'Following',
      feedTitle: 'Feed',
      emptyFeedReport: "This user has no posts yet",
      emptyFeedMessage: 'From the third post this user will start their scores',
      emptyFeedReportUnknown: "This user has no posts in the tribes you follow. Follow him to see all his posts",
      feedTitleBlocked: 'Block',
      blockedReport: 'You blocked this user',
      blockedMessage: "You will not be able to access this user's content.",
      feedTitleBlockedByThisUser: 'Block',
      blockedByThisUserReport: 'You have been blocked by this user',
      blockedByThisUserMessage: "You will not be able to access this user's content.",
      menuOptions: { 
        block: 'Block',
        unlock: 'Unlock'
      }
    },
    profileDetailsScreen: {
      title: 'User Profile',
      successMessage: 'Profile updated successfully',
      validators: {
        nameRequired: 'Name is required',
        emailRequired: 'E-mail is required',
        emailInvalid: 'E-mail entered is invalid',
        usernameRequired: 'Username is required',
        usernameInUse: 'Username already in use',
        usernameInvalid: 'Username can only contain letters and numbers',
        phoneRequired: 'Phone is required',
        webSiteInvalid: 'Site entered is invalid'
      },
      user: {
        name: 'Name',
        email: 'E-mail',
        username: 'Username',
        webSite: 'Web Site',
        bio: 'Biography',
        phone: 'Phone',
        genre: 'Genre',
        language: 'Language',
      },
      genre: {
        male: 'Male',
        female: 'Famale',
        custom: 'Custom',
        preferNotSay: 'Prefer Not Say'
      }
    },
    suggestTribeScreen: {
      title: 'Tribus Suggestion',
      categoriesTitle: 'Preferences',
      tribesTitle: 'Tribus',
      goToFeed: 'Go to Feed',
      backToFeed: 'Back to Feed',
      requestTribe: 'Request New Tribu',
      tour: 'Repeat the Tour'
    },
    settingScreen: {
      title: 'Settings',
      pushNotificationDisabled: 'Push Notifications Disabled?',
      downloadData: 'Download Data',
      tour: 'Repeat the Tour',
      tribeSuggestion: 'Tribus Suggestion'
    },
    tribeItemComponent: {
      followers: 'Followers',
      posts: 'Posts'
    },
    tribeProfileScreen: {
      title: 'Tribu Profile',
      posts: 'Posts',
      followers: 'Followers',
      feedTitle: 'Feed',
      emptyFeedReport: "This tribu don't have any posts yet",
      emptyFeedMessage: 'Be the first person to post to this Tribu'
    },
    searchScreen: {
      searchPlaceholder: 'Search...',
      tabs: {
        all: 'All',
        tribes: 'Tribus',
        people: 'People'
      }
    },
    feedFilterScreen: {
      title: 'Feed Filter',
      postScore: 'Post Score',
      userScore: 'User Score',
      onlyFollowedUser: 'Only Followed User',
      hideDenouncedPosts: 'Hide Denounced Content',
      tribe: 'Tribus',
      contentType: 'Content',
      place: 'Place',
      apply: 'Apply',
      clear: 'Clear',
      contentTypes: {
        image: 'Images',
        video: 'Videos',
        onlyText: 'Only Text',
        youtube: 'Youtube Videos',
        hiperlink: 'Links',
      }
    },
    postScreen: {
      title: 'New Post',
      includeImage: 'Include Image',
      includeVideo: 'Include Video',
      publish: 'Publish',
      includeVideoGallery: 'Include video from Gallery',
      includeVideoRecord: 'Record a video',
      includeVideoExternal: 'External video link',
      includeImageGallery: 'Include image from Gallery',
      takePhoto: 'Take Photo',
      editPhoto: 'Edit Photo',
      imageLimitOut: 'Max #0 images are allowed',
      backMessage: 'Want to save changes this post?',
      textPlaceholder: 'Write a caption...',
      selectTribe: 'Select a Tribu',
      tagFriends: 'Tag Friends',
      location: 'Add Location',
      externalVideoButton: 'Include video',
      externalVideoPlaceholder: 'Insert the video URL',
      imagePreview: 'Image Preview',
      sharedLinkError: "The shared content is not public, so it will not be possible to publish it, try other content or post directly to meeTribu",
      galleryIOSPermission: 'The access to your photos has not been granted, you will not be able to continue',
      galleryAndroidPermission: 'The access to your memory has not been granted, you will not be able to continue',
      cameraIOSPermission: 'The access to your camera has not been granted, you will not be able to continue',
      cameraAndroidPermission: 'The access to your camera and memory has not been granted, you will not be able to continue',
      videoIOSPermission: 'The access to your camera and microphone has not been granted, you will not be able to continue',
      videoAndroidPermission: 'The access to your camera, microphone and memory has not been granted, you will not be able to continue',
      limitedAccessInfo: 'The access to the photos gallery is limited, click here to redirect to settings area to you grant access'
    },
    feedItemComponent: {
      details: 'Post Details',
      cancelDenounce: 'Cancel Denounce',
      mainTranslation: 'Main Translation',
      translatePost: 'Translate',
      score: 'score',
      trySavePost: 'Try Again',
      urlPreviewSeeMore: 'See more'
    },
    searchTribeScreen: {
      title: 'Search Tribus'
    },
    selectLocationScreen: {
      title: 'Add Location'
    },
    requestTribeScreen: {
      title: 'Request New Tribu',
      name: 'Name',
      description: 'Description',
      requestButton: 'Send Request',
      info: 'Want to create another Tribu? suggest a name and description for this Tribu.',
      validators: {
        nameRequired: 'Name is required!',
        descriptionRequired: 'Description is required'
      }
    },
    tribusScreen: {
      title: 'Tribus',
      requestTribe: 'Request New Tribu',
      allCategories: 'All'
    },
    ratingDialog: {
      question: 'Does the content of this post make sense for this Tribu?',
      minText: "It doesn't",
      maxText: 'Do a lot!',
      button: 'Send'
    },
    commentsScreen: {
      title: 'Comments',
      inputPlaceholder: 'Add a comment',
      deleteQuestion: 'Do you want to exclude the comment?'
    },
    cameraScreen: {
      newVideo: 'New Video',
      publishVideo: 'Publish video',
      processingVideo: 'Processing Video',
      maxVideoSize: 'The max video durations is #0. Use link to external video.',
      cameraPermissionTitle: 'Permission to use camera',
      cameraPermissionMessage: 'We need your permission to use your camera',
      recordPermissionTitle: 'Permission to use audio recording',
      recordPermissionMessage: 'We need your permission to use your audio',
    },
    notificationScreen: {
      title: 'Notifications',
      empty: 'Without notifications for you at moment',
      usersPostTribe: 'Added new posts on Tribu #0',
      isFollowingYou: 'started to follow you',
      mentionedYouInAComment: 'mentioned you in a comment',
      mentionedYouInAPost: 'mentioned you in a post',
      commentedOnYourPost: 'commented in your post',
      likedYourComment: 'liked your comment',
      ratedYourPost: 'rated your post',
      clearButton: 'Clear Notifications'
    },
    useTermsScreen: {
      title: 'Use Terms',
      agree: 'Agree',
      useTerms: 'Use Terms',
      eula: 'EULA',
      privacyPolicy: 'Privacy Policy',
      helpCenter: 'Help Center',
      agreementMessage: 'By clicking #agree, you accept the #eula and all #useTerms and #privacyPolicy. You can also access the #helpCenter if you prefer other information.'
    },
    welcomeFeed: {
      title: 'Welcome to meeTribu',
      subtitle: 'Follow the first access steps and have fun :)',
      startButton: 'Start Footsteps',
      jumpButton: 'Jump'
    },
    onboarding: {
      feed: {
        filter: 'Here you can filter what you want to see on your main screen (Feed)! Remember that this is one of our differentials, YOU control your preferences! Use your will!',
        newPost: 'Here you can start posting your content',
      },
      feedItem: {
        info: 'Find out who posted, in which Tribu and for how long',
        config: "Delete your post or report someone else's post by clicking here",
        scoring: "Now it's your turn to score the post! Remember, your evaluation is very important to know if that content helped Tribu. In addition to allowing filtering, the posts 'score is what generates the users' score and turns them into INFLUENCERS!",
        comments: 'Click here and find out what people are saying in the post',
        share: 'Use and abuse sharing! Interesting content can be sent to other apps and from other apps you can also share on meeTribu! Bring and send the best content to your Tribu!',
        score: 'This is the score of the post! Always try to publish content that gives value to Tribus, and REMEMBER, you can choose the score you want to see from both posts and people by filtering your main screen!',
      },
      tab: {
        Tribes: 'In this menu you will browse preferences and Tribus! Meet the unknown! In meeTribu you are welcome in all Tribus, enter, share, score!',
        FeedScreen: 'This is your main screen, here you will see all the Tribus posts you have entered! Enjoy the experience and rate the content that interests you, take the opportunity to interact and make your posts!',
        Notification: 'Here you can follow what is happening again in your Tribus! Rest assured, you will only be notified when you wish, here you are the boss!',
        Profile: 'In this menu you can change your data, place your photo and remember: meeTribu is made up of real people!'
      },
      profile: {
        editButton: 'Edit your profile by clicking here',
        editImage: 'Edit your profile photo by clicking here',
        followers: 'Follow who follows you by clicking here',
        following: 'Follow who you are following by clicking here',
        score: 'Stay tuned to your score! You are the star of your Tribu, generate a lot of interesting content for your Tribus and be an INFLUENCER!'
      },
      suggestion: {
        preferences: 'Browse preferences and find the Tribus you want to know and participate in! MeeTribu was made for you to meet the unknown! Feel free and discover new worlds!'
      },
      search: {
        input: 'Search for users and Tribus as much as you want through this search field',
        tabs: 'Select what you want to search for',
        user: "Here you can access the user's profile",
        tribe: 'Here you can access the profile of Tribu'
      },
      post: {
        text: "It's time to make your post and show your potential! Post interesting content and be the leader of your Tribu! REMEMBER your post should be focused in the context of Tribu, this is the most important!",
        tribe: 'Select the Tribu to post',
        tagFriends: 'Mark your friends and help them remember to score your post!',
        media: 'You can post images and videos! REMEMBER that larger videos can be shared from other applications!',
        location: 'You can also bookmark the locations!'
      }
    },
    denounceScreen: {
      title: 'Denounce',
      send: 'Send'
    },
    feedScreen: {
      loadingSharedMedia: 'Loading shared media',
      noResultsTitle: 'No posts found',
      noResultsSubtitle: 'Search for Tribus of interest by clicking the button below',
      tribesExporer: 'Explore Tribus',
    },
    usersScreen: {
      following: 'Following',
      followed: 'Followers'
    },
    postDetailsScreen: { 
      postDeleted: 'This post was removed by user'
    },
    urlPreview: {
      facebookTitle: 'Facebook Link',
      facebookDescription: 'Click here to access the contents of the link',
      instagramTitle: 'Instagram Link',
      instagramDescription: 'Click here to access the contents of the link',
      twitterTitle: 'Twitter Link',
      twitterDescription: 'Click here to access the contents of the link',
      invalidMetadataTitle: 'External Link',
      invalidMetadataDescription: 'Click here to access the contents of the link'
    },
    requestTribe: {
      successMessage: 'Thank you for collaborating with meeTribu, we will analyze your suggestion'
    },
    editPostScreen: {
      title: 'Edit Post'
    },
    scoringExplanation: {
      title: 'Your Evaluation',
      howWorksTitle: 'How do evaluations work?',
      howWorksContent: `The ratings work on a scale of 1 to 5 stars for each post, with 1 star being ` 
        + `the worst score and 5 stars being the best. The score aims to improve the user experience `
        + `by qualifying the relevant and best rated content, allowing Tribu's audience to filter what`
        + ` they want to see according to the content's relevance (score). Remember that only posts `
        + `are scored. The user's score is a reflection of the average score of his posts made in his `
        + `various Tribus.`,
      benefitsTitle: 'What are the benefits of having a good score?',
      benefitsContent: `The higher your score and that of your posts, the greater the chances ` 
        + `that they will be viewed and that people will interact and be engaged with your content. ` 
        + `Furthermore, the great benefit is that by getting a high score you are more likely to ` 
        + `become an influencer! As an influencer, you will have monetization tools available so ` 
        + `that through your content, sponsors can invest in you!`,
      contentAndCredibilityTitle: 'Generating good content builds credibility',
      contentAndCredibilityContent: `meeTribu is a social network focused on the quality and ` 
        + `organization of content (posts). Generating good posts with focused and relevant content ` 
        + `for Tribus will make people more credible in you and automatically score better, engage ` 
        + `and interact with your posts, turning you into a Tribu Leader or Influencer. The greater ` 
        + `your credibility, the greater the chances that sponsors will invest in you and increase ` 
        + `your level of influence, reflecting the values ​​you will receive from each person's interaction.`
    },
    suggestCategoryScreen: {
      title: 'Select your interests',
      selectTribes: 'Select Tribus'
    },
    downloadUserDataScreen: {
      title: 'Download Data',
      email: 'Email',
      description: `Get a copy of what you've shared on meeTribu`,
      details: `We'll send you an email with a link to a file containing all of your information, posts, `
        + `comments, ratings and more. It may take up to 48 hours for us to collect and send this data to you.`,
      buttonText: 'Request Download',
      successTitle: 'Download Requested',
      successMessage: `We started creating a file with the things you shared and we'll send a link to #0. `
        + `It may take up to 48 hours for us to collect all this data and send it to you. Remember to check `
        + `your spam box if you don't see this email.`,
      requestErrorMessage: 'Unable to request download of your data, please try again in a few minutes',
      invalidEmail: 'The entered email is inválid'
    }
  }
}