import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';

interface ProgressDialogProps {
  loading: boolean
}

function ProgressDialog({ loading }: ProgressDialogProps) {
  return (
    <Dialog
      open={loading}
      hideBackdrop={false}
    >
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}

export default ProgressDialog;