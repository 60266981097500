import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, FormControl, 
  InputAdornment, InputLabel, 
  MenuItem, OutlinedInput, Select, TextField, 
  useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import ImageInput from '../../../components/image-input/ImageInput';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { CategoryModel } from '../../../shared/models/category-model';
import { CategoryService } from '../../../shared/services/category-service';
import { TribeModel, TribeModelSave } from './tribe-model';

interface TribeDetailsProps {
  value?: TribeModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: TribeModelSave) => void
}

const createForm = (value?: TribeModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('name', [Validators.required], value ? value.name : undefined),
    new FormField('description', [Validators.required], value ? value.description : undefined),
    new FormField('categoryId', [Validators.required], value ? value.category : undefined),
    new FormField('image', [Validators.required], value && value.image ? value.image['link'] : undefined),
    new FormField('background', [Validators.required], value && value.background ? value.background.link : undefined),
    new FormField('site', [Validators.url], value ? value.site : undefined),
  ])
}

function TribeDetails({ value, opened, onCancel, onSave }: TribeDetailsProps) {
  const [categories, setCategories] = useState<CategoryModel[]>([])
  const [form, setForm] = useState<FormValidation>(createForm(value))

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: TribeModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    new CategoryService().findAll()
      .then(categories => setCategories(categories))
  }, []);

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Tribe Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-4 mb-3">
            <ImageInput 
              image={form.getFieldValue('image')} 
              onSelectImage={(file) => setForm(form.setValue('image', file))} 
              onDeleteImage={() => setForm(form.setValue('image', undefined))} />
            { form.fieldHasError('image', 'required') && <Alert className="mt-2" severity="error">Image is required!</Alert> }
          </div>
          <div className="col-8 mb-3">
            <ImageInput
              image={form.getFieldValue('background')} 
              onSelectImage={(file) => setForm(form.setValue('background', file))} 
              onDeleteImage={() => setForm(form.setValue('background', undefined))} />
            { form.fieldHasError('background', 'required') && <Alert className="mt-2" severity="error">Background image is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <TextField 
              fullWidth={true}
              required={true}
              id="name"
              label="Name"
              variant="outlined"
              error={!form.fieldValid('name')}
              onBlur={() => setForm(form.markAsDirty('name'))}
              value={form.getFieldValue('name')}
              onChange={event => setForm(form.setValue('name', event.target.value))} 
            />
            { form.fieldHasError('name', 'required') && <Alert className="mt-2" severity="error">Name is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <TextField 
              type="file"
              fullWidth={true}
              multiline={true}
              required={true}
              id="description"
              label="Description"
              variant="outlined"
              rows={4}
              error={!form.fieldValid('description')}
              onBlur={() => setForm(form.markAsDirty('description'))}
              value={form.getFieldValue('description')}
              onChange={event => setForm(form.setValue('description', event.target.value))} 
            />
            { form.fieldHasError('description', 'required') && <Alert className="mt-2" severity="error">Description is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="category">Category *</InputLabel>
              <Select
                error={!form.fieldValid('categoryId')}
                onBlur={() => setForm(form.markAsDirty('categoryId'))}
                value={form.getFieldValue('categoryId')}
                onChange={(e) => setForm(form.setValue('categoryId', e.target.value))}
                label="Category"
              >
                { categories.map((category) => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
              </Select>
            </FormControl>
            { form.fieldHasError('categoryId', 'required') && <Alert className="mt-2" severity="error">Category is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Web Site</InputLabel>
              <OutlinedInput
                fullWidth={true}
                id="site"
                startAdornment={<InputAdornment position="start">@</InputAdornment>}
                labelWidth={68}
                onBlur={() => setForm(form.markAsDirty('site'))}
                value={form.getFieldValue('site')}
                onChange={event => setForm(form.setValue('site', event.target.value))} 
              />
            </FormControl>
            { form.fieldHasError('site', 'url') && <Alert className="mt-2" severity="error">This web site isn't a valid URL!</Alert> }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TribeDetails;