import React from 'react'

export interface HomeProps {
  
}
 
export interface HomeState {
  
}
 
class Home extends React.Component<HomeProps, HomeState> {

  render() { 
    return (
      <div>
        <h1>Home</h1>
      </div>
    );
  }
}
 
export default Home;