import { Dialog, DialogContent, DialogActions, Button, useMediaQuery, useTheme, Snackbar, createStyles, makeStyles, CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab";
import { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../components/custom-button/CustomButton";
import ProgressDialog from "../../../components/progress-dialog/ProgressDialog";
import QuestionDialog from "../../../components/question-dialog/QuestionDialog";
import { DenounceDetailsModel } from "../../../shared/models/denounce-details-model";
import { MediaModel } from "../../../shared/models/media-model";
import { DenounceService } from "../../../shared/services/denounce-service";
import { formatDateTime } from "../../../shared/utils/formaters";
import { extractYoutubeId } from "../../../shared/utils/utils";

export interface DenounceDetailsProps {
  opened: boolean
  denounceId: string
  onClose: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    legend: {
      color: theme.palette.primary.main
    }
  }),
);

const MEDIA_WIDTH = 420
const MEDIA_ASPECT_RATIO = 1

const ACCEPT_CONFIRM_MESSAGE = 'Do you want to delete this post?'
const ACCEPT_SUCCESS_MESSAGE = 'Denounce accepted with success!'
const ACCEPT_ERROR_MESSAGE = 'Error on accept denounce!'

const REFUSE_SUCCESS_MESSAGE = 'Denounce refused with success!'
const REFUSE_ERROR_MESSAGE = 'Error on refuse denounce!'

const ANALYSE_SUCCESS_MESSAGE = 'Denounce analyse started with success!'
const ANALYSE_ERROR_MESSAGE = 'Error on start analyse denounce!'

function DenounceDetails({ opened, onClose, denounceId }: DenounceDetailsProps) {
  const [questionOpened, setQuestionOpened] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<DenounceDetailsModel>()
  const classes = useStyles()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loadDetails = useCallback(() => {
    if (denounceId) {
      DenounceService.findDenounceDetails(denounceId)
        .then(details => {
          setDetails(details)
          console.log(details)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }, [denounceId])

  useEffect(() => loadDetails(), [denounceId, loadDetails])

  const getMediaHeight = (media: MediaModel) => {
    if (media && media.aspectRatio) {
      return MEDIA_WIDTH * media.aspectRatio
    }
    return MEDIA_WIDTH * MEDIA_ASPECT_RATIO
  }

  const analysing = () => {
    if (details) {
      setLoading(true)
      DenounceService.analyseDenounce(details.id)
        .then(() => {
          setLoading(false)
          setDetails({ ...details, status: 'analyzing' })
          setSuccessMessage(ANALYSE_SUCCESS_MESSAGE)
        })
        .catch(() => {
          setLoading(false)
          setErrorMessage(ANALYSE_ERROR_MESSAGE)
        })
    }
  }

  const accept = () => {
    setQuestionOpened(false)
    if (details) {
      setLoading(true)
      DenounceService.acceptDenounce(details.id)
        .then(() => {
          setLoading(false)
          setDetails({ ...details, status: 'accepted' })
          setSuccessMessage(ACCEPT_SUCCESS_MESSAGE)
        })
        .catch(() => {
          setLoading(false)
          setErrorMessage(ACCEPT_ERROR_MESSAGE)
        })
    }
  }

  const refuse = () => {
    if (details) {
      setLoading(true)
      DenounceService.refuseDenounce(details.id)
        .then(() => {
          setLoading(false)
          setDetails({ ...details, status: 'refused' })
          setSuccessMessage(REFUSE_SUCCESS_MESSAGE)
        })
        .catch(() => {
          setLoading(false)
          setErrorMessage(REFUSE_ERROR_MESSAGE)
        })
    }
  }

  return (
    <>
      <ProgressDialog loading={loading} />
      <QuestionDialog
        message={ACCEPT_CONFIRM_MESSAGE}
        opened={questionOpened}
        onClose={() => setQuestionOpened(false)}
        onYes={accept}
        onNo={() => setQuestionOpened(false)}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSuccessMessage(undefined)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={opened}
        hideBackdrop={false}
      >
        <DialogContent>
          { !details && (
            <div className="d-flex justify-content-center m-4">
              <CircularProgress />
            </div>
          ) }
          { details && (
            <section>
              <h3>{details.spam ? 'Spam' : details.reason.reason}</h3>
              { details.option && <h4>{details.option}</h4> }
              <h5>{formatDateTime(new Date(details.creationDate))}</h5>

              <div className="d-flex">
                <article className="py-3 pr-3">
                  <fieldset>
                    <legend className={classes.legend}>User Denouncer</legend>
                    <h5>{details.userDenouncer.name}</h5>
                    <p className="m-0">{'@' + details.userDenouncer.userName}</p>
                    <p className="m-0">{details.userDenouncer.email}</p>
                    <p className="m-0">{details.userDenouncer.telefone}</p>
                  </fieldset>
                </article>

                <article className="py-3 pr-3">
                  <fieldset>
                    <legend className={classes.legend}>Post User</legend>
                    <h5>{details.userDenounced.name}</h5>
                    <p className="m-0">{'@' + details.userDenounced.userName}</p>
                    <p className="m-0">{details.userDenounced.email}</p>
                    <p className="m-0">{details.userDenounced.telefone}</p>
                  </fieldset>
                </article>
              </div>

              <article>
                <fieldset>
                  <legend className={classes.legend}>Post Details</legend>
                  <h5>{details.postDenounced.tribeName}</h5>
                  <p className="m-0">{formatDateTime(new Date(details.postDenounced.creationDate))}</p>
                  <p className="m-0">{details.postDenounced.text}</p>

                  <section className="d-flex flex-wrap">
                    { details.postDenounced.images && details.postDenounced.images.map((image, index) => (
                      <figure key={index} className="mt-3 mr-3">
                        <img 
                          src={image.link} alt={'Feed image' + (index + 1)} 
                          width={MEDIA_WIDTH}
                          height={getMediaHeight(image)} />
                      </figure>
                    ))}
                  </section>

                  <section>
                    { details.postDenounced.video && (
                      <video 
                        className="mt-3"
                        style={{
                          objectFit: 'cover'
                        }}
                        controls
                        width={MEDIA_WIDTH}
                        height={getMediaHeight(details.postDenounced.video)}>
                        <source src={details.postDenounced.video.link} type="video/mp4" />
                      </video>
                    ) }
                  </section>

                  <section>
                    { details.postDenounced.externalVideo && details.postDenounced.externalVideo.link && (
                      <iframe
                        className="mt-3"
                        title="Youtube Video"
                        width="420" 
                        height="315"
                        src={`https://www.youtube.com/embed/${extractYoutubeId(details.postDenounced.externalVideo.link)}`}>
                      </iframe>
                    ) }
                  </section>
                </fieldset>
              </article>
            </section>
          ) }
        </DialogContent>
        <DialogActions>
          { details && (
            <>
              { !['accepted', 'analyzing'].includes(details.status) && (
                <CustomButton 
                  variant="contained"
                  backgroundColor={'#e39400'}
                  color={'white'}
                  onClick={analysing}
                >Analyse Post / Disable</CustomButton>
              ) }
              { !['accepted'].includes(details.status) && (
                <CustomButton 
                  variant="contained"
                  backgroundColor={'#b80000'}
                  color={'white'}
                  onClick={() => setQuestionOpened(true)}
                >Accept Denounce / Delete Post</CustomButton>
              ) }
              { !['accepted', 'refused'].includes(details.status) && (
                <CustomButton 
                  variant="contained"
                  backgroundColor={'#057d02'}
                  color={'white'}
                  onClick={refuse}
                >Refuse Denounce / Enable</CustomButton>
              ) }
            </>
          ) }
          <Button color={'secondary'}onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DenounceDetails
