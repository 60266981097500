import { CircularProgress, createStyles, IconButton, makeStyles, Radio, SvgIconTypeMap, Theme } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useEffect, useState } from "react";
import { Actions } from '../actions/Actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions1: {
      width: '124px'
    },
    actions2: {
      width: '62px'
    },
    column: {
      verticalAlign: 'middle !important'
    }
  }),
);

interface TableProps {
  columns: Column[]
  dataSouce: any[]
  loading: boolean
  actions?: TableAction[]
  selectable?: 'multiple' | 'single'
  onEdit?: (value: any) => void
  onDelete?: (value: any) => void
  onSelect?: (items: any[]) => void
  onAction?: (action: TableAction, item: any) => void
}

export interface TableAction {
  target: string
  displayName: string
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  color?: string
  hoverColor?: string
}

export interface Column {
  fieldName: string
  displayName: string
  format?: (value: any) => string | undefined
}

function Table({ columns, dataSouce, loading, actions, selectable, onEdit, onDelete, onSelect, onAction }: TableProps) {
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const classes = useStyles()
  const hasAction = !!onEdit || !!onDelete

  const isChecked = (item: any) => {
    return selectedItems.includes(item)
  }

  const toggle = (item: any) => {
    if (selectable === 'single') {
      setSelectedItems([item])
    }
    if (selectable === 'multiple') {
      if (selectedItems.includes(item)) {
        setSelectedItems([...selectedItems.filter(i => i !== item)])
      } else {
        setSelectedItems([...selectedItems, item])
      }
    }
  }

  useEffect(() => {
    onSelect && onSelect(selectedItems)
  }, [onSelect, selectedItems])

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          {selectable && selectable === 'single' && <th>Select</th>}
          {selectable && selectable === 'multiple' && <th></th>}
          {hasAction && <th scope="col" className={!onEdit || !onDelete ? classes.actions2 : classes.actions1}>Actions</th>}
          {columns.map((column, index) => <th scope="col" key={index}>{column.displayName}</th>)}
          {actions && actions.map((action, index) => <th scope="col" key={index}>{action.displayName}</th>)}
        </tr>
      </thead>
      <tbody>
        {dataSouce.map((item, index) => (
          <tr key={index}>
            {selectable && selectable === 'single' && (
              <td>
                <Radio 
                  color="primary"
                  checked={isChecked(item)}
                  onChange={() => toggle(item)} />
              </td>
            )}
            {selectable && selectable === 'multiple' && <td></td>}
            {hasAction && (
              <td className={classes.column}>
                <Actions 
                  onEdit={onEdit ? () => onEdit(item) : undefined}
                  onDelete={onDelete ? () => onDelete(item) : undefined}/>
              </td>
            )}
            { columns.map((column, index) => (
              <td className={classes.column} key={index}>{
                column.format ? column.format(item[column.fieldName]) : item[column.fieldName]
              }</td>
            ))}
            { actions && actions.map((action, index) => {
              const Icon = action.icon
              return (
                <td className={classes.column} key={index}>
                  <IconButton
                    key={index}
                    aria-label="action" 
                    onClick={() => {
                      onAction && onAction(action, item)
                    }}>
                    <Icon style={{ color: action.color }}></Icon>
                  </IconButton>
                </td>
              )
            })}
          </tr>
        ))}
        {loading && (
          <tr>
            <td
              className={classes.column}
              colSpan={columns.length + (hasAction ? 1 : 0)+ (actions ? actions.length : 0) + (selectable ? 1 : 0)}
            >
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            </td>
          </tr>
        )}
        {!loading && (!dataSouce || dataSouce.length === 0) && (
          <tr>
            <td
              className={classes.column}
              colSpan={columns.length + (hasAction ? 1 : 0) + (actions ? actions.length : 0) + (selectable ? 1 : 0)}
            >
              <div className="d-flex justify-content-center">
                <h6 className="m-0">No Data</h6>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default Table;