import Tribes from './../../pages/registrations/tribes/Tribes';
import Header from './../../components/header/Header';
import Template from './../../components/template/Template';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './../../pages/home/Home';
import Categories from './../../pages/registrations/categories/Categories';
import DenounceReasons from './../../pages/registrations/denounce-reasons/DenounceReasons';
import { useAuth } from '../../contexts/hooks/useAuth';
import SignInSide from './../signin/SignIn'
import { CircularProgress, makeStyles } from '@material-ui/core';
import Denounce from '../manage/denounce/Denounce';
import Languages from '../registrations/language/Language';
import Translations from '../registrations/translation/Translation';
import Variables from '../registrations/variables/Variables';

const useStyles = makeStyles(() => ({
  progressContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function Main() {
  const classes = useStyles();

  const auth = useAuth()

  if (auth.status === 'SIGNED_IN') {
    return (
      <div>
        <Header />
        <Router>
          <Template>
            <Switch>
              <Route path="/variables">
                <Variables />
              </Route>
              <Route path="/languages">
                <Languages />
              </Route>
              <Route path="/translations">
                <Translations />
              </Route>
              <Route path="/categories">
                <Categories />
              </Route>
              <Route path="/tribes">
                <Tribes />
              </Route>
              <Route path="/reasons">
                <DenounceReasons />
              </Route>
              <Route path="/denounces">
                <Denounce />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Template>
        </Router>
      </div>
    )
  }

  if (auth.status === 'SIGNED_OUT') {
    return <SignInSide />
  }

  return (
    <div className={classes.progressContainer}>
      <CircularProgress color="primary"></CircularProgress>
    </div>
  )
}

export default Main
