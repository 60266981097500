import { createContext, useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import Axios from 'axios';

type AuthStatus = 'LOADING' | 'SIGNED_OUT' | 'SIGNED_IN'

interface AuthContextType {
  accessToken?: string
  status: AuthStatus
  signIn?: (accessToken: string) => void
  signOut?: () => void
}

const defaultAuthContext: AuthContextType = {
  accessToken: undefined,
  status: 'LOADING',
}

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>('LOADING')
  const [accessToken, setAccessToken] = useState<string>()

  useEffect(() => {
    setTimeout(() => {
      const accessToken = localStorage.getItem('meetribu.accessToken')
      if (accessToken) {
        console.log(jwt_decode(accessToken))
        signIn(accessToken)
      } else {
        signOut()
      }
    }, 2000)
    
  }, [])

  const signIn = (newAccessToken: string) => {
    localStorage.setItem('meetribu.accessToken', newAccessToken)
    Axios.defaults.headers.common.Authorization = `bearer ${newAccessToken}`
    setAccessToken(newAccessToken)
    setStatus('SIGNED_IN')
  }

  const signOut = () => {
    setAccessToken(undefined)
    setStatus('SIGNED_OUT')
  }

  return (
    <AuthContext.Provider value={{
      accessToken,
      status,
      signIn,
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;