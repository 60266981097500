import { Button, Card, CardContent, CardHeader, Icon, Snackbar, TextField } from '@material-ui/core';
import React from 'react';
import { TribeModel as ValueModel, TribeModelSave as ValueModelSave } from './tribe-model';
import { TribeService as ValueService } from './tribe-service';
import Pagination from '@material-ui/lab/Pagination';
import Table, { Column } from '../../../components/table/Table';
import TribeDetails from './TribeDetails';
import ProgressDialog from '../../../components/progress-dialog/ProgressDialog';
import { Alert } from '@material-ui/lab';
import QuestionDialog from '../../../components/question-dialog/QuestionDialog';
import { AbstractRegistrationService } from '../../../shared/services/abstract-registration-service';

interface TribesProps {
  
}

const TITLE = 'Tribes'
const SAVE_SUCCESS_MESSAGE = 'Category saved with success!'
const SAVE_ERROR_MESSAGE = 'Error on save tribe!'
const DELETE_SUCCESS_MESSAGE = 'Category deleted with success!'
const DELETE_ERROR_MESSAGE = 'This tribe cannot be deleted!'
const DELETE_CONFIRM_MESSAGE = 'Do you want to delete this tribe?'
const COLLUMNS: Column[] = [
  { fieldName: 'name', displayName: 'Name' },
  { fieldName: 'description', displayName: 'Description' },
  { fieldName: 'categoryName', displayName: 'Category' },
]

interface TribesState {
  values: ValueModel[]
  value?: ValueModel
  text: string
  loading: boolean
  count: number
  opened: boolean
  loadingDialog: boolean
  successMessage?: string
  errorMessage?: string
  page: number
  valueToDelete?: ValueModel
  questionOpened: boolean
}

export default class Tribes extends React.Component<TribesProps, TribesState> {

  state: TribesState = {
    values: [],
    value: undefined,
    text: '',
    loading: false,
    count: 1,
    opened: false,
    loadingDialog: false,
    successMessage: undefined, 
    errorMessage: undefined,
    page: 1,
    questionOpened: false,
    valueToDelete: undefined
  }

  ativo = true

  service: AbstractRegistrationService<ValueModel, ValueModelSave>

  constructor(props: TribesProps) {
    super(props);
    this.service = new ValueService()
  }

  setValues = (values: ValueModel[]) => this.ativo && this.setState({ values })
  setValue = (value: ValueModel) => this.ativo && this.setState({ value })

  setLoading = (loading: boolean) => this.ativo && this.setState({ loading })
  setLoadingDialog = (loadingDialog: boolean) => this.ativo && this.setState({ loadingDialog })
  setSuccessMessage = (successMessage?: string) => this.ativo && this.setState({ successMessage })
  setErrorMessage = (errorMessage?: string) => this.ativo && this.setState({ errorMessage })
  setText = (text: string) => {
    this.ativo && this.setState({ text }, () => this.loadValues(true))
  }
  setCount = (count: number) => this.ativo && this.setState({ count })
  setOpened = (opened: boolean) => this.ativo && this.setState({ opened })
  setPage = (page: number) => this.ativo && this.setState({ page })
  
  setValueToDelete = (valueToDelete?: ValueModel) => this.ativo && this.setState({ valueToDelete })
  setQuestionOpened = (questionOpened: boolean) => this.ativo && this.setState({ questionOpened })

  componentDidMount() {
    this.loadValues(true)
  }

  componentWillUnmount() {
    this.ativo = false
  }

  loadValues(reset = false) {
    if (reset) {
      this.setPage(1)
    }
    this.setLoading(true)
    this.service.findPageCount(this.state.text)
      .then((count) => {
        this.setCount(count)
        return this.service.findPage(this.state.text, reset ? 0 : this.state.page - 1) 
      })
      .then(values => {
        this.setLoading(false)
        this.setValues(values)
      })
      .catch(e => {
        this.setLoading(false)
        console.log(e)
      })
  }

  onSave(value: ValueModelSave) {
    this.setLoadingDialog(true)
    this.service.save(value)
      .then(valueSaved => {
        if (value.id) {
          this.setValues([...this.state.values.map((v: ValueModel) => v.id === value.id ? valueSaved : v)])
        } else {
          this.setValues([...this.state.values, valueSaved])
        }
        this.setLoadingDialog(false)
        this.setSuccessMessage(SAVE_SUCCESS_MESSAGE)
      }, e => {
        console.log(e)
        this.setErrorMessage(SAVE_ERROR_MESSAGE)
        this.setLoadingDialog(false)
      })
    this.setOpened(false)
  }

  onEdit(value: ValueModel) {
    this.setValue(value)
    this.setOpened(true)
  }

  onDelete(value: ValueModel) {
    this.setValueToDelete(value)
    this.setQuestionOpened(true)
  }

  delete() {
    this.setQuestionOpened(false)
    const valueToDelete = this.state.valueToDelete
    if (valueToDelete && valueToDelete.id) {
      this.setLoadingDialog(true)
      this.service.delete(valueToDelete.id)
        .then(() => {
          this.setValues(this.state.values.filter((v: ValueModel) => v.id !== valueToDelete.id))
          this.setValueToDelete()
          this.setLoadingDialog(false)
          this.setSuccessMessage(DELETE_SUCCESS_MESSAGE)
        }, e => {
          this.setErrorMessage(DELETE_ERROR_MESSAGE)
          this.setLoadingDialog(false)
          this.setValueToDelete()
        })
    }
  }

  render() {
    const { 
      loading, values, text, count, opened, value,
      loadingDialog, successMessage, errorMessage, page, 
      questionOpened 
    } = this.state
    return (
      <div className="m-2">
        <Card>
          <ProgressDialog loading={loadingDialog} />
          <QuestionDialog 
            message={DELETE_CONFIRM_MESSAGE} 
            opened={questionOpened}
            onClose={() => this.setQuestionOpened(false)} 
            onYes={() => this.delete()}
            onNo={() => this.setQuestionOpened(false)}
          />
          <Snackbar 
            open={!!successMessage} 
            autoHideDuration={6000} 
            onClose={() => this.setSuccessMessage(undefined)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={() => this.setSuccessMessage(undefined)} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar 
            open={!!errorMessage} 
            autoHideDuration={6000} 
            onClose={() => this.setErrorMessage(undefined)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={() => this.setErrorMessage(undefined)} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
          { opened && (
            <TribeDetails
              value={value}
              opened={opened}
              onSave={(value) => this.onSave(value)}
              onCancel={() => this.setOpened(false)}
            />
          ) }
          <CardHeader title={(
            <h4 className="m-0">{TITLE}</h4>
          )} />
          <CardContent>
            <div className="d-flex mb-3">
              <div className="flex-grow-1 pr-3">
                <TextField 
                  className="w-100"
                  value={text}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setText(event.target.value)} 
                  label="Search"
                  variant="outlined" />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setOpened(true)
                  console.log('Clicou')
                }}
                endIcon={<Icon>add</Icon>}
              >
                Insert
              </Button>
            </div>
            <Table 
              columns={COLLUMNS} 
              dataSouce={values} 
              loading={loading}
              onEdit={(value) => this.onEdit(value)}
              onDelete={(value) => this.onDelete(value)} />
            <div className="d-flex justify-content-end">
              <Pagination count={count} page={page} color="primary" onChange={(e, value) => {
                this.setPage(value)
                this.loadValues()
              }} />
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}