import { Button, createStyles, Dialog, DialogActions, 
  DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, 
  useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import TextListInput from '../../../components/text-list-input/TextListInput';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { languages } from '../../../shared/utils/constants';
import { DenounceReasonModel, DenounceReasonModelSave } from '../../../shared/models/denounce-reason-model';
import { DenounceReasonService } from '../../../shared/services/denounce-reason-service';

const useStyles = makeStyles((theme) =>
  createStyles({
    inputList: {
      maxHeight: '200px',
      overflow: 'auto'
    },
  }),
);

interface DenounceReasonDetailsProps {
  value?: DenounceReasonModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: DenounceReasonModelSave) => void
}

const createForm = (value?: DenounceReasonModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('reason', [Validators.required], value ? value.reason : undefined),
    new FormField('language', [Validators.required], value ? value.language : undefined),
    new FormField('type', [Validators.required], value ? value.type : undefined),
    new FormField('guidelinesTitle', [], value ? value.guidelinesTitle : undefined),
    new FormField('guidelines', [], value ? value.guidelines : undefined),
    new FormField('customSearch', [], value ? value.customSearch : undefined),
    new FormField('options', [], value ? value.options : undefined),
  ])
}

export function DenounceReasonDetails({ value, opened, onCancel, onSave }: DenounceReasonDetailsProps) {
  const classes = useStyles()
  const [form, setForm] = useState<FormValidation>(createForm(value))

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: DenounceReasonModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Denounce Reason Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 col-md-8 mb-3">
            <TextField 
              fullWidth={true}
              required={true}
              id="reason"
              label="Denounce reason"
              variant="outlined"
              error={!form.fieldValid('reason')}
              onBlur={() => setForm(form.markAsDirty('reason'))}
              value={form.getFieldValue('reason')}
              onChange={event => setForm(form.setValue('reason', event.target.value))} 
            />
            { form.fieldHasError('reason', 'required') && <Alert className="mt-2" severity="error">Denounce reason is required!</Alert> }
          </div>
          <div className="col-12 col-md-4 mb-3">
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="language" style={{ backgroundColor: 'white' }}>Language *</InputLabel>
              <Select
                error={!form.fieldValid('language')}
                onBlur={() => setForm(form.markAsDirty('language'))}
                value={form.getFieldValue('language')}
                onChange={(e) => setForm(form.setValue('language', e.target.value))}
                label="Language"
              >
                { languages.map((language) => <MenuItem key={language.code} value={language.code}>{language.name}</MenuItem>)}
              </Select>
            </FormControl>
            { form.fieldHasError('language', 'required') && <Alert className="mt-2" severity="error">Language is required!</Alert> }
          </div>
          <div className="col-12 col-md-4 mb-3">
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="type" style={{ backgroundColor: 'white' }}>Type *</InputLabel>
              <Select
                error={!form.fieldValid('type')}
                onBlur={() => setForm(form.markAsDirty('type'))}
                value={form.getFieldValue('type')}
                onChange={(e) => setForm(form.setValue('type', e.target.value))}
                label="Type"
              >
                { DenounceReasonService.findDenounceReasonTypes().map((item) => <MenuItem key={item.type} value={item.type}>{item.description}</MenuItem>)}
              </Select>
            </FormControl>
            { form.fieldHasError('type', 'required') && <Alert className="mt-2" severity="error">Type is required!</Alert> }
          </div>
          
          <div className="col-12 col-md-8 mb-3">
            <TextField 
              fullWidth={true}
              id="customSearch"
              label="Custom Search"
              variant="outlined"
              value={form.getFieldValue('customSearch')}
              onChange={event => setForm(form.setValue('customSearch', event.target.value))} 
            />
          </div>
          <div className="col-12 mb-3">
            <TextListInput 
              listClassName={classes.inputList}
              label="Options"
              inputLabel="Option"
              variant="outlined"
              values={form.getFieldValue('options')}
              onValuesChange={values => setForm(form.setValue('options', values))} 
            />
          </div>
          <div className="col-12 mb-3">
            <TextField 
              fullWidth={true}
              id="guidelinesTitle"
              label="Guidelines Title"
              variant="outlined"
              value={form.getFieldValue('guidelinesTitle')}
              onChange={event => setForm(form.setValue('guidelinesTitle', event.target.value))} 
            />
          </div>
          <div className="col-12 mb-3">
            <TextListInput 
              listClassName={classes.inputList}
              inputLabel="Guideline"
              label="Guidelines"
              variant="outlined"
              values={form.getFieldValue('guidelines')}
              onValuesChange={values => setForm(form.setValue('guidelines', values))} 
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
