/* istanbul ignore file */
import Axios from 'axios'
import { meeTribuEnv } from '../meetribu.env'

export const AuthService = {
  signIn(email: string, password: string): Promise<string> {
    return Axios.post(meeTribuEnv.getBaseUrl() + '/api-auth/v1/signin-admin', {
      email,
      password
    })
    .then(resp => resp.data.accessToken)
  },
}
