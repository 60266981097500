import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';

interface QuestionDialogProps {
  opened: boolean
  message: string
  onYes: () => void
  onNo: () => void
  onClose: () => void
}

function QuestionDialog({ opened, message, onYes, onNo, onClose }: QuestionDialogProps) {
  return (
    <Dialog
      open={opened}
      hideBackdrop={true}
      onClose={onClose}
    >
      <DialogContent>
        <h5>{message}</h5>
      </DialogContent>
      <DialogActions>
        <Button onClick={onYes} color="secondary">Yes</Button>
        <Button onClick={onNo} color="primary">No</Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionDialog;