export function fileToSrc(file: File): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const binaryData = e.target?.result;
      if (binaryData) {
        resolve(binaryData);
      } else {
        reject('no data')
      }
    };
    reader.onerror = error => reject(error);
  });
}

export const extractYoutubeId = (link: string) => {
  if (!link) {
    return null
  }
  let videoId = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  if (videoId != null && videoId.length > 0 && typeof videoId[1] === 'string') {
    return videoId[1]
  } else { 
    return null
  }
}