import { Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, TextField, 
  useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { FormField, FormValidation } from '../../../shared/form-validation/form-validation';
import { Validators } from '../../../shared/form-validation/validators';
import { VariableModel, VariableModelSave } from '../../../shared/models/variable-model';

interface VariableDetailsProps {
  value?: VariableModel,
  opened: boolean
  onCancel: () => void
  onSave: (value: VariableModelSave) => void
}

const createForm = (value?: VariableModel) => {
  return new FormValidation([
    new FormField('id', [], value ? value.id : undefined),
    new FormField('code', [Validators.required], value ? value.code : undefined),
    new FormField('value', [Validators.required], value ? value.value : undefined),
    new FormField('description', [Validators.required], value ? value.description : undefined),
  ])
}

function VariableDetails({ value, opened, onCancel, onSave }: VariableDetailsProps) {
  const [form, setForm] = useState<FormValidation>(createForm(value))

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const submit = () => {
    setForm(form.markAllAsDirty())
    if (form.valid()) {
      const value: VariableModelSave = {
        ...form.getValue()
      }
      setForm(form.clear())
      onSave(value)
    }
  }

  useEffect(() => {
    if (value) {
      setForm(createForm(value))
    } else {
      setForm(form => form.clear())
    }
  }, [value]);
  
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={opened}
      hideBackdrop={false}
    >
      <DialogTitle>{"Variable Details"}</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 mb-3">
            <TextField 
              type="file"
              fullWidth={true}
              multiline={true}
              required={true}
              id="description"
              label="Description"
              variant="outlined"
              rows={4}
              error={!form.fieldValid('description')}
              onBlur={() => setForm(form.markAsDirty('description'))}
              value={form.getFieldValue('description')}
            />
            { form.fieldHasError('description', 'required') && <Alert className="mt-2" severity="error">Description is required!</Alert> }
          </div>
          <div className="col-12 mb-3">
            <TextField 
              fullWidth={true}
              required={true}
              id="value"
              label="Value"
              variant="outlined"
              error={!form.fieldValid('value')}
              onBlur={() => setForm(form.markAsDirty('value'))}
              value={form.getFieldValue('value')}
              onChange={event => setForm(form.setValue('value', event.target.value))}
            />
           { form.fieldHasError('value', 'required') && <Alert className="mt-2" severity="error">Value is required!</Alert> }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setForm(form.clear())
          onCancel()
        }}>
          Cancel
        </Button>
        <Button onClick={() => submit()} autoFocus color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VariableDetails;