import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

interface ActionsProps {
  onEdit?: (value: any) => void;
  onDelete?: (value: any) => void
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    editIcon: {
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    deleteIcon: {
      '&:hover': {
        color: theme.palette.error.main
      }
    }
  })
)

export const Actions = ({ onEdit, onDelete }: ActionsProps) => {
  const classes = useStyles()
  return (
    <div>
      {onEdit && (
        <IconButton 
          className={classes.editIcon} 
          aria-label="edit" 
          onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          className={classes.deleteIcon} 
          aria-label="delete" 
          onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
};